import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { setLoading, setError, clearError } from "store/msgStore";
import { Badge } from "react-bootstrap";
import { ApiGet } from "services/ApiService";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter, numberFilter, selectFilter, Comparator } from 'react-bootstrap-table2-filter';
import { FiFolderMinus, FiFolderPlus, } from "react-icons/fi";
import KeyValueDisplay from "./KeyValuesDisplay";
import AdminOrderItems from "./AdminOrderItems";

export default function AdminOrders({ curentUser }) {

    const [data, setData] = useState([]); // need to do a deep copy

    let dispatch = useDispatch();

    const prepareData = (loadedData) => {

        setData(
            loadedData.map((d) => ({
                ...d,
                'idComandaNice': `${d.serie} ${d.numar}`,
                'valoare': `${parseFloat(d.valoare).toFixed(2)}`,
                'data': new Intl.DateTimeFormat('ro-RO', { day: '2-digit', month: 'long', year: 'numeric' }).format(new Date(d.data)),
            })
            ));

        dispatch(setLoading(false));

    }

    const refresh = () => {
        console.log("loading orders");
        dispatch(clearError());
        dispatch(setLoading(true));
        ApiGet('/orders', (data) => { prepareData(data) }, (error) => { dispatch(setLoading(false)); dispatch(setError(error)); });
    }

    const selectOptionsDaNu = {
        'NU': 'NU',
        'DA': 'DA',
    };

    const selectOptionsMoneda = {
        'EUR': 'EUR',
        'RON': 'RON',
    };

    useEffect(() => {
        //render
    }, [data]);

    useEffect(() => {
        refresh();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns = [
        {
            dataField: 'serie',
            text: 'S.',
            sort: true,
            filter: textFilter(),
            headerStyle: { width: '50px' }
        },
        {
            dataField: 'numar',
            text: 'Nr',
            sort: true,
            filter: textFilter(),
            headerStyle: { width: '80px' }
        },
        {
            dataField: 'data',
            text: 'Data',
            sort: true,
            filter: textFilter(),
            headerStyle: { width: '150px' },
            align: 'right'
        },
        {
            dataField: 'valoare',
            text: 'Valoare',
            sort: true,
            filter: numberFilter({
                defaultValue: { comparator: Comparator.GE },
                placeholder: '-',
            }),
            headerStyle: { width: '10%' },
            align: 'right'
        },
        {
            dataField: 'moneda',
            text: 'Moneda',
            sort: true,
            filter: selectFilter({
                placeholder: 'oricare',
                options: selectOptionsMoneda
            }),
            headerStyle: { width: '6%' },
            align: 'center'
        },
        {
            dataField: 'client',
            text: 'Client',
            sort: true,
            filter: textFilter(),
        },
        {
            dataField: 'userName',
            text: 'User Name',
            sort: true,
            filter: textFilter(),
            headerStyle: { width: '10%' },
        },
        {
            dataField: 'anulata',
            text: 'Anl.',
            sort: true,
            filter: selectFilter({
                placeholder: 'oricare',
                options: selectOptionsDaNu
            }),
            align: 'center',
            headerStyle: { width: '50px' }
        },
        {
            dataField: 'operata',
            text: 'Opr.',
            sort: true,
            filter: selectFilter({
                placeholder: 'oricare',
                options: selectOptionsDaNu
            }),
            align: 'center',
            headerStyle: { width: '50px' }
        },
        {
            dataField: 'blocata',
            text: 'Blc.',
            sort: true,
            filter: selectFilter({
                placeholder: 'oricare',
                options: selectOptionsDaNu
            }),
            align: 'center',
            headerStyle: { width: '50px' }
        },
        {
            dataField: 'inchisa',
            text: 'Inc.',
            sort: true,
            filter: selectFilter({
                placeholder: 'oricare',
                options: selectOptionsDaNu
            }),
            align: 'center',
            headerStyle: { width: '50px' }
        },
    ]

    const defaultSorted = [{
        dataField: 'name', // if dataField is not match to any column you defined, it will be ignored.
        order: 'asc' // desc or asc
    }];

    const expandRow = {
        renderer: row => (
            <>
                <h3><Badge pill bg="info" >{row.idComandaNice}</Badge> {row.client} <Badge bg="success" ><strong>{row.valoare}</strong> {row.moneda}</Badge></h3>
                <p className="text-muted">{row.codFiscal} {row.registruComert}</p>

                <KeyValueDisplay row={row} columns={4} />
                
                <AdminOrderItems order={row} />
            </>
        ),
        showExpandColumn: true,
        expandByColumnOnly: true,
        expandHeaderColumnRenderer: ({ isAnyExpands }) => {
            return (<></>);
        },
        expandColumnRenderer: ({ expanded }) => {
            if (expanded) {
                return (<FiFolderMinus />);
            }
            return (<FiFolderPlus />);
        }
    };

    return (
        <>
            <h1>Comenzi</h1>
            <p>Comenzile sunt sincronizate cu Win-Mentor și sunt afișate ca atare.
                Orice operație asupra comenzii trebuie operată în WinMentor.</p>
            <p>Sunt în total {data.length} comenzi:</p>
            {!!data && !!data.map && (<BootstrapTable
                hover
                keyField='idComanda'
                data={JSON.parse(JSON.stringify(data))}
                columns={columns}
                defaultSorted={defaultSorted}
                filter={filterFactory()}
                pagination={paginationFactory()}
                expandRow={expandRow}
                condensed
            />
            )}
            <hr />
        </>
    );
}