import { Card } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';

function RequestAccessCard() {
    const navigate = useNavigate();

    const handleRedirect = () => {
      navigate('/cerere');
    };
    
    return (
        <Card style={{ background: 'linear-gradient(to bottom,  rgba(128, 128, 128, 0.75), rgba(0, 0, 0, 0.75))', height: '90%' }}>
            <Card.Body style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between', // Space between title/content and button
            }}>
                <Card.Title className="mb-3" style={{ color: 'white', fontWeight: 'bold', fontSize: '1.5em' }}>Deveniți partener B2B</Card.Title>

                <p style={{ color: 'white', fontSize: '.8em' }}>
                    Sunteți redistribuitor și doriți să profitați de următoarele avantaje:
                    <ul>
                        <li>Acces la multe mărci premium</li>
                        <li>Funcție avansată de căutare și navigare</li>
                        <li>Comenzi direct din stoc</li>
                    </ul>
                </p>


                <div className="form-group mt-3 text-start">
                    <button className="btn btn-light btn-block" onClick={handleRedirect}>
                        Solicitați acces
                    </button>
                </div>

            </Card.Body>
        </Card>
    )
}

export default RequestAccessCard;
