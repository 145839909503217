import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const KeyValueDisplay = ({ row, columns = 2 }) => {
    const entries = Object.entries(row);

    // Split data into chunks based on the number of columns
    const chunkSize = Math.ceil(entries.length / columns);
    const chunks = [];
    for (let i = 0; i < entries.length; i += chunkSize) {
        chunks.push(entries.slice(i, i + chunkSize));
    }

    const camelToSentence = (text) => {
        if (!text) return '';
        // Insert a space before each uppercase letter and convert the first letter to uppercase
        const sentence = text.replace(/([A-Z])/g, ' $1').toLowerCase();
        return sentence.charAt(0).toUpperCase() + sentence.slice(1);
    };

    return (
        <Container fluid>
            <Row>
                {chunks.map((chunk, colIndex) => (
                    <Col key={colIndex}>
                        {chunk.map(([key, value], index) => (
                            <div style={{fontSize:'.8em'}} key={index} className="mb-0">
                                <strong>{camelToSentence(key)}:</strong> {value}
                            </div>
                        ))}
                    </Col>
                ))}
            </Row>
        </Container>
    );
};

export default KeyValueDisplay;
