import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { setLoading, setError, clearError } from "store/msgStore";
import { Badge } from "react-bootstrap";
import { ApiGet } from "services/ApiService";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter, numberFilter, selectFilter, Comparator } from 'react-bootstrap-table2-filter';
import { FiFolderMinus, FiFolderPlus, } from "react-icons/fi";
import KeyValueDisplay from "./KeyValuesDisplay";

export default function AdminFacturi({ curentUser }) {

    const [data, setData] = useState([]); // need to do a deep copy

    let dispatch = useDispatch();

    const prepareData = (loadedData) => {

        setData(
            loadedData.map((d) => ({
                ...d,
                'valoare': `${parseFloat(d.valoare).toFixed(2)}`,
                'dataFactura': new Intl.DateTimeFormat('ro-RO', { day: '2-digit', month: 'long', year: 'numeric' }).format(new Date(d.dataFactura)),
            })
            ));

        dispatch(setLoading(false));

    }

    const refresh = () => {
        console.log("loading invoices");
        dispatch(clearError());
        dispatch(setLoading(true));
        ApiGet('/invoices', (data) => { prepareData(data) }, (error) => { dispatch(setLoading(false)); dispatch(setError(error)); });
    }

    const selectOptionsMoneda = {
        'EUR': 'EUR',
        'RON': 'RON',
    };

    useEffect(() => {
        //render
    }, [data]);

    useEffect(() => {
        refresh();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns = [
        {
            dataField: 'codDocument',
            text: 'Cod',
            sort: true,
            filter: textFilter(),
            headerStyle: { width: '100px' }
        },
        {
            dataField: 'serie',
            text: 'Serie',
            sort: true,
            filter: textFilter(),
            headerStyle: { width: '80px' }
        },
        {
            dataField: 'numar',
            text: 'Nr',
            sort: true,
            filter: textFilter(),
            headerStyle: { width: '80px' }
        },
        
        {
            dataField: 'dataFactura',
            text: 'Data',
            sort: true,
            filter: textFilter(),
            headerStyle: { width: '150px' },
            align: 'right'
        },
        {
            dataField: 'valoare',
            text: 'Valoare',
            sort: true,
            filter: numberFilter({
                defaultValue: { comparator: Comparator.GE },
                placeholder: '-',
            }),
            headerStyle: { width: '10%' },
            align: 'right'
        },
        
        {
            dataField: 'tva',
            text: 'TVA',
            sort: true,
            filter: numberFilter({
                defaultValue: { comparator: Comparator.GE },
                placeholder: '-',
            }),
            headerStyle: { width: '10%' },
            align: 'right'
        },
            
        {
            dataField: 'sold',
            text: 'Sold',
            sort: true,
            filter: numberFilter({
                defaultValue: { comparator: Comparator.GE },
                placeholder: '-',
            }),
            headerStyle: { width: '10%' },
            align: 'right'
        },
        {
            dataField: 'moneda',
            text: 'Moneda',
            sort: true,
            filter: selectFilter({
                placeholder: 'oricare',
                options: selectOptionsMoneda
            }),
            headerStyle: { width: '6%' },
            align: 'center'
        },
        {
            dataField: 'denumire',
            text: 'Client',
            sort: true,
            filter: textFilter(),
        }
    ]

    const defaultSorted = [{
        dataField: 'name', // if dataField is not match to any column you defined, it will be ignored.
        order: 'asc' // desc or asc
    }];

    const expandRow = {
        renderer: row => (
            <>
                <h3><Badge pill bg="info" >{row.serie} {row.numar}</Badge> {row.denumire} <Badge bg="success" ><strong>{row.valoare}</strong> {row.moneda}</Badge></h3>
                <p className="text-muted">{row.codFiscal} {row.registruComert}</p>

                <KeyValueDisplay row={row} columns={4} />
            </>
        ),
        showExpandColumn: true,
        expandByColumnOnly: true,
        expandHeaderColumnRenderer: ({ isAnyExpands }) => {
            return (<></>);
        },
        expandColumnRenderer: ({ expanded }) => {
            if (expanded) {
                return (<FiFolderMinus />);
            }
            return (<FiFolderPlus />);
        }
    };

    return (
        <>
            <h1>Facturi</h1>
            <p>Comenzile sunt sincronizate cu Win-Mentor și sunt afișate ca atare.
                Orice operație asupra facturii trebuie operată în WinMentor.</p>
            <p>Sunt în total {data.length} Facturi:</p>
            {!!data && !!data.map && (<BootstrapTable
                hover
                keyField='codDocument'
                data={JSON.parse(JSON.stringify(data))}
                columns={columns}
                defaultSorted={defaultSorted}
                filter={filterFactory()}
                pagination={paginationFactory()}
                expandRow={expandRow}
                condensed
            />
            )}
            <hr />
        </>
    );
}