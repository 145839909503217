import { useState, useEffect } from "react";
import { Alert, Button } from "react-bootstrap"
import { ApiPost } from "services/ApiService";
import { useNavigate } from "react-router-dom";


export default function ChangePasswordButton({ currentUser }) {
    const [message, setMessage] = useState("");

    const navigate = useNavigate();

    useEffect(() => {
        // render
    }, [message]);

    const resetPassword = (e) => {
        e.preventDefault();

        ApiPost('/changepasstoken', { username: currentUser.username },
            (data) => {
                console.log("Change pass token response", data);
                navigate("/schimbare-parola/" + data.token + "/" + data.expire);
            },
            (error) => {
                console.log("Change-pass token error", error.response.data);
                setMessage(error.response.data);
            });
    }

    return (
        !!message ? (
            <Alert variant='danger'>
                {message}
            </Alert>
        ) : (
            <Button variant="warning" className="btn-sm align-right" onClick={resetPassword}>Schimbă parola {message}</Button>
        )


    )
}