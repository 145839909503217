import { useEffect, useState } from "react";
import { Badge, Button, Card, Col, ListGroup, Row } from "react-bootstrap";
import { ApiGet } from "services/ApiService";
import AlertBox from "components/common/AlertBox";
import LoadingSpinner from "components/common/LoadingSmall";
import axios from "axios";


export default function AdminOrdersImport({ curentUser }) {
    const [data, setData] = useState([]);
    const [error, setError] = useState(false);

    useEffect(() => {
        refreshStatus();
    }, []);

    useEffect(() => {
    }, [data, error]);

    if (error) {
        return (
            <AlertBox message={data} />
        )
    }

    if (!data) {
        return (
            <LoadingSpinner />
        )
    }

    const startImport = async () => {
        try {
            await axios.get('/cron/sync-comenzi.php');
            console.log("Import started successfully.");
        } catch (error) {
            console.error("Error while starting the import:", error);
        }
    };
    

    const refreshStatus = () => {
        ApiGet('/orders/status', setData, setError);
    }

    return (
        <>
            <Row xs={1} md={2} className="g-4">
                <Col>
                    <Card className="p-0">
                        <Card.Body>
                            <Card.Title>Comenzi existente in DB</Card.Title>
                        </Card.Body>
                        <ListGroup variant="flush">
                            {!!(data) &&
                                !!(data.totals) && (
                                    <ListGroup.Item>Comenzi existente in baza de date: <strong>{!!(data.totals.orders) ? data.totals.orders : "-"}</strong> </ListGroup.Item>
                                )}
                        </ListGroup>

                    </Card>
                </Col>
                <Col>
                    <Card className="p-0">
                        <Card.Body>
                            <Card.Title>Sincronizare comenzi</Card.Title>
                        </Card.Body>

                        {!!(data) &&
                            !!(data.import) && (
                                <>
                                    <ListGroup variant="flush">
                                        <ListGroup.Item>Inceput import: {!!(data.import.start) ? (data.import.start) : "-"}</ListGroup.Item>
                                        <ListGroup.Item>
                                            Sfarsit import: {data.import.last || "-"} &nbsp;
                                            {data.import.lastStatus && (
                                                <Badge
                                                    bg={
                                                        data.import.lastStatus === "1"
                                                            ? "success"
                                                            : data.import.lastStatus === "0"
                                                                ? "danger"
                                                                : "gray"
                                                    }
                                                >
                                                    {data.import.lastStatus === "1"
                                                        ? "success"
                                                        : data.import.lastStatus === "0"
                                                            ? "eroare"
                                                            : "?"}
                                                </Badge>
                                            )}
                                        </ListGroup.Item>
                                        <ListGroup.Item>Ultimul import cu success: {!!(data.import.lastSuccess) ? (data.import.lastSuccess) : "-"} </ListGroup.Item>
                                    </ListGroup>
                                    <Card.Body>
                                        <Button onClick={refreshStatus}>Refresh</Button> &nbsp;
                                        <Button className="ml-3" onClick={startImport} variant='warning'>Import now</Button>
                                    </Card.Body>
                                </>
                            )}
                    </Card >
                </Col>

            </Row>
        </>
    )
}