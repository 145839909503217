import { useState } from 'react'
import Form from 'react-validation/build/form'
import Input from 'react-validation/build/input'
import CheckButton from 'react-validation/build/button'
import { login } from 'services/AuthService'
import { Card, Row, Col } from 'react-bootstrap'
import { NavLink } from "react-router-dom";

function LoginForm() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [form, setForm] = useState();
    const [checkBtn, setCheckBtn] = useState();

    const onChangeUsername = e => {
        setUsername(e.target.value);
    }

    const onChangePassword = e => {
        setPassword(e.target.value);
    }

    const required = value => {
        if (!value) {
            return (
                <div className="alert alert-danger" role="alert">
                    Vă rugăm introduceți o valoare!
                </div>
            );
        }
    };

    const handleLogin = e => {
        e.preventDefault();

        setMessage('');
        setLoading(true);

        form.validateAll();

        if (checkBtn.context._errors.length === 0) {
            console.log("Check ok, loggin in", username);

            login(username, password)
                .then(() => {
                    window.location.href = '/profile';
                },
                    error => {
                        const resMessage =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();

                        console.log("Error message", resMessage);

                        setLoading(false);
                        setMessage("Eroare la autentificare: " + resMessage);

                    }
                );
        } else {
            setLoading(false);
        }
    }

    return (
        <Card style={{ background: 'linear-gradient(to bottom, rgba(255, 165, 0, .75) 0%,  rgba(204, 120, 34, .75) 50%, rgba(179, 105, 33, .75) 100%)', height: '90%' }}>
            <Card.Body style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between', // Space between title/content and button
            }}>
                <Card.Title className="mb-3" style={{ color: 'black', fontWeight: 'bold', fontSize: '1.5em' }}>Conectați-vă acum</Card.Title>

                <Form
                    onSubmit={handleLogin}
                    ref={c => {
                        setForm(c);
                    }}
                >

                    <div className="form-group">
                        <label htmlFor="username" style={{ color: 'white' }}>Adresa de email</label>
                        <Input
                            type="text"
                            className="form-control"
                            name="username"
                            value={username}
                            onChange={onChangeUsername}
                            validations={[required]}
                            style={{ opacity: 0.85 }}
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="password" style={{ color: 'white' }}>Parola</label>
                        <Input
                            type="password"
                            className="form-control"
                            name="password"
                            value={password}
                            onChange={onChangePassword}
                            validations={[required]}
                            style={{ opacity: 0.85 }}
                        />
                    </div>

                    <Row className="d-flex justify-content-between align-items-center mt-3">
                        <Col xs="auto">
                            <div className="form-group mt-3 text-center">
                                <button
                                    className="btn btn-light btn-block"
                                    style={{ backgroundColor: 'orange', borderColor: 'black' }}
                                    disabled={loading}
                                >
                                    {loading ? (
                                        <span className="spinner-border spinner-border-sm"></span>
                                    ) : (
                                        <span>Conectați-vă</span>
                                    )}
                                </button>
                            </div>
                        </Col>
                        <Col xs="auto">
                            <div className="form-group mt-3 text-center small">
                                <NavLink style={{ color: 'grey', textDecoration: 'none' }} to="/recuperare-parola">Recuperare parolă</NavLink>
                            </div>
                        </Col>
                    </Row>

                    {message && (
                        <div className="form-group mt-1">
                            <div className="alert alert-danger" role="alert">
                                {message}
                            </div>
                        </div>
                    )}

                    <div className="form-group">
                        <CheckButton
                            style={{ display: "none" }}
                            ref={c => {
                                setCheckBtn(c);
                            }}
                        />
                    </div>


                </Form>
            </Card.Body>
        </Card>
    )
}

export default LoginForm;
