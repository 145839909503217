import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { setLoading, setError, clearError } from "store/msgStore";
import { Badge, Col, Container, Row } from "react-bootstrap";
import { ApiGet } from "services/ApiService";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter} from 'react-bootstrap-table2-filter';


export default function AdminOrderItems({ order }) {

    const [data, setData] = useState([]); // need to do a deep copy

    let dispatch = useDispatch();

    const prepareData = (loadedData) => {
        setData(loadedData);
        dispatch(setLoading(false));
    }

    const refresh = () => {
        console.log("loading order", order);
        dispatch(clearError());
        dispatch(setLoading(true));
        ApiGet('/orders/' + order.idComanda, (data) => { prepareData(data) }, (error) => { dispatch(setLoading(false)); dispatch(setError(error)); });
    }


    useEffect(() => {
        //render
    }, [data]);

    useEffect(() => {
        refresh();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns = [
        {
            dataField: 'denumire',
            text: 'Denumire',
            headerStyle: { width: '40%' },

       },
        {
            dataField: 'itemId',
            text: 'Cod'     
        },
        {
            dataField: 'codIntern',
            text: 'Cod intern' 
        }, /*
        {
            dataField: 'cantComandat',
            text: 'Cant. comandat',
            sort: true,
            filter: numberFilter(),
          
        },
        {
            dataField: 'cantAcceptat',
            text: 'Cant. acceptat',
            sort: true,
            filter: numberFilter(),

        },*/
        {
            dataField: 'um',
            text: 'UM',
            headerStyle: { width: '70px' },

          
        },
       /* {
            dataField: 'pret',
            text: 'Pret',
            sort: true,
            filter: numberFilter({
                defaultValue: { comparator: Comparator.GE },
                placeholder: '-',
            }),
            align: 'right'
        }, */
        {
            dataField: 'termenLivrare',
            text: 'Termen Livrare',
        },
        {
            dataField: 'observatii',
            text: 'Observatii',
        },
       
    ]

    const defaultSorted = [{
        dataField: 'name', // if dataField is not match to any column you defined, it will be ignored.
        order: 'asc' // desc or asc
    }];


    return (
        <>
            <p>Sunt în comandă sunt {data.length} produse:</p>
            {!!data && !!data.map && (<BootstrapTable
                hover
                keyField='id'
                data={JSON.parse(JSON.stringify(data))}
                columns={columns}
                defaultSorted={defaultSorted}
                filter={filterFactory()}
                pagination={paginationFactory()}
                condensed
            />
            )}
            <hr />
        </>
    );
}