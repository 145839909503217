import { Card, CardBody, CardHeader } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setError, clearError } from "store/msgStore";
import { ApiGet } from "services/ApiService";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';


export default function PastOrders() {
    const STATUS_BLOCATA = ' BLOCATA ';
    const STATUS_INCHISA = ' INCHISA ';
    const STATUS_ANULATA = ' ANULATA ';
    const STATUS_OPERATA = ' OPERATA ';

    const [data, setData] = useState([]);
    const [niceColumns, setNiceColumns] = useState([]);

    const dispatch = useDispatch();

    const columns = [
        {
            dataField: 'codComanda',
            text: 'Cod comanda'
        },
        {
            dataField: 'idComanda',
            text: 'Serie numar comanda'
        },
        {
            dataField: 'data',
            text: 'Data',
            align: 'center'
        },
        {
            dataField: 'valoare',
            text: 'Valoare',
            align: 'right'
        },
        {
            dataField: 'zilePlata',
            text: 'Zile plata',
            align: 'right'
        },
        {
            dataField: 'status',
            text: 'STARE',
            align: 'center'
        },
        {
            dataField: 'obervatii',
            text: 'Observatii',
            align: 'center'
        },
    ]

    const prepareColumns = () => {
        setNiceColumns(
            data.map((d) => ({
                'id': d.id,
                'codComanda': d.codComanda,
                'idComanda': `${d.serie} ${d.numar}`,
                'valoare': `${parseFloat(d.valoare).toFixed(2)} ${d.moneda}`,
                'data': new Intl.DateTimeFormat('ro-RO', { day: '2-digit', month: 'long', year: 'numeric' }).format(new Date(d.data)),
                'zilePlata': d.zilePlata,
                'status': `${d.anulata === 'DA' ? STATUS_ANULATA : ''}${d.operata === 'DA' ? STATUS_OPERATA : ''}${d.blocata === 'DA' ? STATUS_BLOCATA : ''}${d.inchisa === 'DA' ? STATUS_INCHISA : ''}`,
                'obervatii': d.obervatii,
            })
            ));

    }

    const getRowStyle = (row, rowIndex) => {
        const styles = {}

        if (row.status === STATUS_OPERATA) {
            styles.backgroundColor = 'lightGreen'
        }
        if (row.status === STATUS_ANULATA) {
            styles.backgroundColor = 'gray'
        }
        if (row.status === STATUS_INCHISA) {
            styles.backgroundColor = 'lightGray'
        }
        if (row.status === STATUS_BLOCATA) {
            styles.backgroundColor = 'lightRed'
        }

        return styles;
    }

    const refresh = () => {
        dispatch(clearError());
        ApiGet('/comenzi', (data) => { setData(data) }, (error) => { dispatch(setError(error)); });
    }

    useEffect(() => {
        refresh();
        // eslint-disable-next-line  
    }, [])

    useEffect(() => {
        prepareColumns();
        // eslint-disable-next-line  
    }, [data])

    return (
        <Card>
            <CardHeader>
                Comenzi
            </CardHeader>
            <CardBody>
                <BootstrapTable
                    hover
                    //striped
                    keyField='id'
                    data={JSON.parse(JSON.stringify(niceColumns))}
                    columns={columns}
                    condensed
                    cellStyle={getRowStyle}
                    pagination={paginationFactory()}

                />
            </CardBody>
        </Card>

    )
}