import { useState } from 'react'
import { Form, Button, Alert, Card } from 'react-bootstrap';
import VideoHeaderSection from './VideoHeaderSection';

// Nume, Companie, CIF, Telefon, Email, Adresă)
function RequestAccessForm() {
    const [name, setName] = useState('');
    const [company, setCompany] = useState('');
    const [cif, setCif] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');

    // form boilerplate
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});

    const validateForm = () => {
        const newErrors = {};
        if (!name) newErrors.name = 'Numele este obligatoriu';
        if (!company) newErrors.company = 'Numele firmei este obligatoriu';
        if (!cif) newErrors.cif = 'CIF-ul este obligatoriu';
        if (!phone) newErrors.phone = 'Telefonul este obligatoriu';
        else if (!/^\d{10}$/.test(phone)) newErrors.phone = 'Telefonul trebuie să aibă 10 cifre';
        if (!email) newErrors.email = 'Email-ul este obligatoriu';
        else if (!/\S+@\S+\.\S+/.test(email)) newErrors.email = 'Email-ul nu este valid';
        if (!address) newErrors.address = 'Adresa este obligatorie';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            setLoading(true);
            setTimeout(() => {
                setLoading(false);
                setMessage('Formularul a fost trimis cu succes!');
            }, 2000);
        } else {
            setMessage('');
        }
    };

    return (
        <VideoHeaderSection
            videoSrc="vid/rulmenti2.mp4"
            title="Deveniți partener B2B"
            subtitle="Începutul unei relații de lungă durată"
        >
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100vh'
            }}>

                <Card style={{ marginTop: '0px', marginX: 'auto', maxWidth: '800px', background: 'linear-gradient(to bottom, rgba(255, 255, 255, .85) 0%,  rgba(214, 214, 214, .85) 50%, rgba(255, 255, 255, .85) 100%)' }}>
                    <Card.Header style={{ backgroundColor: 'orange' }} >
                        <Card.Title className="mb-1 mt-3" style={{ color: 'black', fontWeight: 'normal', fontSize: '1.2em' }}>Completați formularul de mai jos pentru a deveni partener B2B</Card.Title>
                        <Card.Body className='m-0 mb-1 p-0 small'>Vom reveni cu un răspuns în 24h</Card.Body>
                    </Card.Header>
                    <Card.Body style={{}}>

                        <Form onSubmit={handleSubmit}>
                            {/* Name Field */}
                            <Form.Group controlId="name">
                                <Form.Label style={{ color: 'black' }}>Numele dumneavoastră</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    isInvalid={!!errors.name}
                                    style={{ opacity: 0.85 }}
                                />
                                <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                            </Form.Group>

                            {/* Company Field */}
                            <Form.Group controlId="company">
                                <Form.Label style={{ color: 'black' }}>Numele firmei</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={company}
                                    onChange={(e) => setCompany(e.target.value)}
                                    isInvalid={!!errors.company}
                                    style={{ opacity: 0.85 }}
                                />
                                <Form.Control.Feedback type="invalid">{errors.company}</Form.Control.Feedback>
                            </Form.Group>

                            {/* CIF Field */}
                            <Form.Group controlId="cif">
                                <Form.Label style={{ color: 'black' }}>Codul de identificare fiscală al firmei</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={cif}
                                    onChange={(e) => setCif(e.target.value)}
                                    isInvalid={!!errors.cif}
                                    style={{ opacity: 0.85 }}
                                />
                                <Form.Control.Feedback type="invalid">{errors.cif}</Form.Control.Feedback>
                            </Form.Group>

                            {/* Phone Field */}
                            <Form.Group controlId="phone">
                                <Form.Label style={{ color: 'black' }}>Telefonul de contact</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                    isInvalid={!!errors.phone}
                                    style={{ opacity: 0.85 }}
                                />
                                <Form.Control.Feedback type="invalid">{errors.phone}</Form.Control.Feedback>
                            </Form.Group>

                            {/* Email Field */}
                            <Form.Group controlId="email">
                                <Form.Label style={{ color: 'black' }}>Email</Form.Label>
                                <Form.Control
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    isInvalid={!!errors.email}
                                    style={{ opacity: 0.85 }}
                                />
                                <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                            </Form.Group>

                            {/* Address Field */}
                            <Form.Group controlId="address">
                                <Form.Label style={{ color: 'black' }}>Adresa de livrare</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    value={address}
                                    rows={3}
                                    onChange={(e) => setAddress(e.target.value)}
                                    isInvalid={!!errors.address}
                                    style={{ opacity: 0.85 }}
                                />
                                <Form.Control.Feedback type="invalid">{errors.address}</Form.Control.Feedback>
                            </Form.Group>

                            {/* Submit Button */}
                            <div className="text-center mt-3">
                                <Button
                                    variant="warning"
                                    type="submit"
                                    style={{ backgroundColor: 'orange', borderColor: 'black' }}
                                    disabled={loading}
                                >
                                    {loading ? <span className="spinner-border spinner-border-sm"></span> : 'Trimiteți cererea'}
                                </Button>
                            </div>

                            {/* Error or Success Message */}
                            {message && (
                                <Alert className="mt-3" variant={errors ? 'danger' : 'success'}>
                                    {message}
                                </Alert>
                            )}
                        </Form>



                    </Card.Body>
                </Card>

            </div>
        </VideoHeaderSection>
    )
}

export default RequestAccessForm;