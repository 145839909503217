import React from 'react';

function VideoHeaderSection({ videoSrc, containerStyle, title, subtitle, children }) {
  return (
    <div style={{ position: 'relative',  width: '100%',  minHeight: '80vh', overflow: 'hidden', ...containerStyle }}>
      <video
        autoPlay
        muted
        loop
        playsInline
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          zIndex: -1,
        }}
      >
        <source src={videoSrc} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <div style={{ margin: '3em' }}>
        <h1 style={{ marginTop: '1em', color: 'orange' }}>{title}</h1>
        <p style={{ marginTop: '.3em', color: 'grey', fontSize: '1em' }}>{subtitle}</p>
      </div>

      {/* Render dynamic content */}
      {children}
    </div>
  );
}

export default VideoHeaderSection;
