import { Card, CardBody, CardHeader } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setError, clearError } from "store/msgStore";
import { ApiGet } from "services/ApiService";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';


export default function Agent() {
    const [data, setData] = useState([]);

    const dispatch = useDispatch();

    const refresh = () => {
        dispatch(clearError());
        ApiGet('/agent', (data) => { setData(data) }, (error) => { dispatch(setError(error)); });
    }

    useEffect(() => {
        refresh();
        // eslint-disable-next-line  
    }, [])

    console.log(data);
    
    return (
        <Card>
            <CardHeader>
                Contactați-ne direct
            </CardHeader>
            <CardBody>
                Agentul dumneavoastră este: <strong>{data.prenume} {data.nume}</strong>
                <p>Email: {data.email}</p> 
                <p>Telefon: {data.telefon}</p>                 
            </CardBody>
        </Card>

    )
}