import { Container, Row, Col } from 'react-bootstrap'
import LoginForm from "./LoginForm";
import RequestAccessCard from './RequestAccessCard';
import VideoHeaderSection from './VideoHeaderSection';

function Login() {
    return (
        <VideoHeaderSection
            videoSrc="vid/rulmenti2.mp4"
            title="Acces parteneri B2B"
            subtitle="Comenzi direct din stoc, peste 90.000 de produse."
        >
            <Container>
                <Row className="justify-content-evenly">
                    <Col md={6} lg={4} xs={12}>
                        <LoginForm />
                    </Col>
                    <Col md={6} lg={4} xs={12}>
                        <RequestAccessCard />
                    </Col>
                </Row>
            </Container>
        </VideoHeaderSection>
    );
}

export default Login;
