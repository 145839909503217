import { Row, Col, Card, CardBody, CardHeader, CardFooter, Badge } from "react-bootstrap";
import ChangePasswordButton from "../password/ChangePasswordButton";
import Agent from "./Agent";

export default function Welcome({ currentUser }) {

  console.log(currentUser)

  return (
    <Row xs={1} md={2} className="g-2">
      <Col>
        <Card>
          <CardHeader style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <span>Partener B2B</span> <small><Badge pill bg="info">#{currentUser.meta.userId}</Badge></small>
          </CardHeader>
          <CardBody>
            <h3>Bună ziua, {currentUser.meta.firstName} {currentUser.meta.lastName}</h3>
            <h5>{currentUser.meta.denumire}</h5>
            <p>{currentUser.meta.codFiscal}<br />
              {currentUser.meta.registruComert}</p>
              <ul>
                <li>Ultimul login reușit: <strong>{currentUser.info.lastSuccesfullLogin}</strong></li>
                <li>Ultimul login eșuat: <strong>{currentUser.info.lastFailedAttempt}</strong></li>
                <li>Erori login: <strong>{currentUser.info.failedAttempts}</strong></li>
            </ul>
          </CardBody>
          <CardFooter style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <span><strong>Email:</strong>{" "} {currentUser.username}</span>
            <ChangePasswordButton currentUser={currentUser} />
          </CardFooter>
        </Card>
      </Col>
      <Col>
        <Agent />
      </Col>
    </Row>
  )
}
